import React from 'react';

const Logo = () => {
  return (
    <h1 className='font-extrabold'>MIT</h1>
  );
};

export const LogoStudio = () => {
  return (
    <h1 className='font-extrabold text-center'>MIT Solutions</h1>
  );
}

export default Logo;
